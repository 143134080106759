import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../../components/images/BackgroundImage';
import QMCloudsMobiiliEtusivuImg from '../../components/images/QMCloudsMobiiliEtusivuImg';

export default () => {
  const { t } = useTranslation();
  return (
    <BackgroundImage className="hero">
      <Container>
        <Row>
          <Col lg={8}>
            <h1>{t('OccupationalSafetySystemTitle')}</h1>
            <p>{t('OccupationalSafetySystemParagraph')}</p>
          </Col>
          <Col lg={4}>
            <QMCloudsMobiiliEtusivuImg />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
