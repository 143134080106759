import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContactRequest from '../../components/ContactRequest';
import performanceImg from '../../images/performance.svg';

export default () => {
  const { t } = useTranslation();

  return (
    <Container className="container-space">
      <Row>
        <Col lg={7}>
          <h2 className="text-blue">
            {t('BenefitsOfOccupationSafetySystemTitle')}
          </h2>
          <div className="line blue-line" />
          <p>{t('BenefitsOfOccupationSafetySystemparagraph1')}</p>
          <p>{t('BenefitsOfOccupationSafetySystemParagraph2')}</p>
          <p>{t('BenefitsOfOccupationSafetySystemParagraph3')}</p>
          <ContactRequest />
        </Col>
        <Col lg={5} className="mt-5">
          <img
            src={performanceImg}
            className="img img-w-400 block auto"
            alt={t('WhatQualityIsTP5ImgAlt')}
          />
        </Col>
      </Row>
    </Container>
  );
};
