import React from 'react';
import Layout from '../components/layout';
import QualityManualRequestWithBg from '../components/QualityManualRequestWithBg';
import SEO from '../components/seo';
import withI18next from '../components/withI18Next';
import QmcloudsOgImg from '../images/qmclouds-og-img.png';
import AboutOccupationalSafetySystem from '../pages-partials/occupational-safety-system/AboutOccupationalSafetySystem';
import BenefitsOfOccupationalSafetySystem from '../pages-partials/occupational-safety-system/BenefitsOfOccupationalSafetySystem';
import DocumentedInformation from '../pages-partials/occupational-safety-system/DocumentedInformation';
import OccupationalSafetySystemHero from '../pages-partials/occupational-safety-system/OccupationalSafetySystemHero';
import ReduceAccidents from '../pages-partials/occupational-safety-system/ReduceAccidents';
import RisksAssessment from '../pages-partials/occupational-safety-system/RisksAssessment';
import TakeBenefitsOutOf from '../pages-partials/occupational-safety-system/TakeBenefitsOutOf';
import WhereToStartSafetySystem from '../pages-partials/occupational-safety-system/WhereToStartSafetySystem';

const OccupationalSafetySystemPage = ({ pageContext: { locale }, t }: any) => (
  <Layout locale={locale}>
    <SEO
      title={t('OccupationalSafetySystemSeoTitle')}
      description={t('OccupationalSafetySystemSeoDescription')}
      pageImage={QmcloudsOgImg}
      pageUrl="https://qmclouds.com/occupational-safety-system"
      lang={locale}
    />
    <OccupationalSafetySystemHero />
    <ReduceAccidents />
    <AboutOccupationalSafetySystem />
    <RisksAssessment />
    <WhereToStartSafetySystem />
    <DocumentedInformation />
    <TakeBenefitsOutOf />
    <QualityManualRequestWithBg />
    <BenefitsOfOccupationalSafetySystem />
  </Layout>
);

export default withI18next({ ns: 'common' })(OccupationalSafetySystemPage);
