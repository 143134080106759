import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space text-center">
      <Row>
        <Col>
          <h2 className="text-blue">{t('RisksAssesmentTitle')}</h2>
          <p>{t('RisksAssessmentParagraph1')}</p>
          <p className="font-weight-bold text-blue">
            {t('RisksAssessmentParagraph2')}
          </p>
        </Col>
      </Row>
    </Container>
  );
};
