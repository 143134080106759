import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import QMCloudsTietokoneRaporttiImg from '../../components/images/QMCloudsTietokoneRaporttiImg';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space">
      <Row>
        <Col lg={6}>
          <h2 className="text-blue">{t('ReduceAccidentsTitle')}</h2>
          <div className="line blue-line" />
          <p>{t('ReduceAccidentsParagraph1')}</p>
          <p>{t('ReduceAccidentsParagraph2')}</p>
          <p>{t('ReduceAccidentsParagraph3')}</p>
        </Col>
        <Col lg={6} className="mt-5">
          <QMCloudsTietokoneRaporttiImg className="img-w-500" />
        </Col>
      </Row>
    </Container>
  );
};
