import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContactRequest from '../../components/ContactRequest';
import BackgroundImage from '../../components/images/BackgroundImage';
import LinkTo from '../../components/LinkTo';
import urls from '../../components/urls';
import onlineImg from '../../images/online.svg';

export default () => {
  const { t } = useTranslation();
  return (
    <BackgroundImage>
      <Container className="container-space text-white">
        <Row>
          <Col lg={6}>
            <h2 className="text-white">
              {t('AboutOccupationalSafetySystemTitle2')}
            </h2>
            <div className="line white-line" />
            <p className="text-white">
              {t('AboutOccupationalSafetySystemParagraph2')}
            </p>
            <p className="text-white">
              {t('AboutOccupationalSafetySystemParagraph3')}
            </p>
            <ContactRequest isBackgroundBlue />
            <div className="mt-4" />
            <LinkTo
              url={`${urls.softwareUrl}#features`}
              text={t('LinkToSoftwareFeatures')}
              hoverWhite
            />
          </Col>
          <Col lg={6}>
            <h2 className="text-white">
              {t('AboutOccupationalSafetySystemTitle')}
            </h2>
            <div className="line white-line" />
            <p className="text-white">
              {t('AboutOccupationalSafetySystemParagraph')}
            </p>
            <img
              src={onlineImg}
              className="img img-w-300 block m-auto"
              alt={t('AboutOccupationSafetySystemTitle')}
            />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
