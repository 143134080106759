import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContactRequest from '../../components/ContactRequest';
import QMCloudsLaptopMobileImage from '../../components/images/QMCloudsLaptopMobileImg';
import QMCloudsMobileBgtImg from '../../components/images/QMCloudsMobileBgImg';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="padding-bottom">
      <Row>
        <Col lg={7}>
          <h2 className="text-blue">{t('WhereToStartSafetySystemTitle')}</h2>
          <div className="line blue-line" />
          <p>{t('WhereToStartSafetySystemTitleParagraph1')}</p>
          <p>{t('WhereToStartSafetySystemTitleParagraph2')}</p>
          <p>{t('WhereToStartSafetySystemTitleParagraph3')}</p>
        </Col>
        <Col lg={5} className="mt-5">
          <QMCloudsLaptopMobileImage className="img-w-450" />
        </Col>
      </Row>
      <Row className="mt-5 mb-5">
        <Col>
          <h3 className="text-blue text-center">
            {t('WhereToStartSafetySystemH3Title1')}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <h4 className="text-blue">{t('WhereToStartSafetySystemH5Title1')}</h4>
          <div className="line blue-line" />
          <ul>
            <li className="mb-3">{t('WhereToStartSafetySystemLi1')}</li>
            <li>{t('WhereToStartSafetySystemLi2')}</li>
          </ul>
        </Col>
        <Col lg={6}>
          <h4 className="text-blue">{t('WhereToStartSafetySystemH5Title2')}</h4>
          <div className="line blue-line" />
          <ul>
            <li className="mb-3">{t('WhereToStartSafetySystemLi3')}</li>
            <li>{t('WhereToStartSafetySystemLi4')}</li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col lg={5}>
          <QMCloudsMobileBgtImg className="img-w-300" />
        </Col>
        <Col lg={7} className="mt-5">
          <h3>{t('WhereToStartSafetySystemParagraph')}</h3>
          <ContactRequest />
        </Col>
      </Row>
    </Container>
  );
};
