import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../../components/images/BackgroundImage';
import QMCloudsKeskeisimmatDokumenttipohjatImage from '../../components/images/QMCloudsKeskeisimmatDokumenttipohjatImage';
import LinkTo from '../../components/LinkTo';
import urls from '../../components/urls';

export default () => {
  const { t } = useTranslation();
  return (
    <BackgroundImage>
      <Container className="container-space text-white">
        <Row>
          <Col lg={6} className="mt-5">
            <h2 className="text-white">{t('DocumentedInformationTitle')}</h2>
            <div className="line white-line"></div>
            <p className="text-white">{t('DocumentedInformationParagraph')}</p>
            <LinkTo
              url={`${urls.qualityUrl}#documents`}
              text={t('ReadMoreEditableDocuments')}
              hoverWhite
            />
            <LinkTo
              url={urls.softwareUrl}
              text={t('ReadMoreLinkToSoftware')}
              hoverWhite
            />
          </Col>
          <Col className="mt-4">
            <QMCloudsKeskeisimmatDokumenttipohjatImage className="img-rounded img-w-500" />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
