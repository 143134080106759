import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import QMCloudsMobiiliEtusivuImg from '../../components/images/QMCloudsMobiiliEtusivuImg';
import LinkTo from '../../components/LinkTo';
import urls from '../../components/urls';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space">
      <Row>
        <Col lg={7}>
          <h2 className="text-blue">{t('TakeBenefitsOutOfTitle')}</h2>
          <div className="line blue-line" />
          <p>{t('TakeBenefitsOutOfParagraph1')}</p>
          <p>{t('TakeBenefitsOutOfParagraph2')}</p>
          <LinkTo
            url={`${urls.softwareUrl}#features`}
            text={t('LinkToSoftwareFeatures')}
          />
        </Col>
        <Col lg={5} className="mt-4">
          <QMCloudsMobiiliEtusivuImg />
        </Col>
      </Row>
    </Container>
  );
};
